import Loyalty from '../../Loyalty.svg';

export const Hero = () => {
    
    return (
        <div className="home_hero">
            {/* <div id="loyalty-content">
                <img src={Loyalty} alt="" className='loyalty-img'/>
                <form action="" className="loyalty-form">
                <input type="text" className="loyalty-signup-email" placeholder='Email address'/>
                <button className="loyalty-signup-btn">SIGN UP</button>
                </form>
            </div> */}
        </div>
    )
}
